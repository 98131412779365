let available = !!window.Bridge
let loaded = false

// Lazy load only when necessary but as early as possible.
if (available) {
  import('./main').then(() => {
    loaded = true
  })
}

export const onMobileAppChromecastLoadedIfAvailable = (cb) => {
  if (loaded || !available) {
    cb()
  } else {
    setTimeout(() => onMobileAppChromecastLoadedIfAvailable(cb), 100)
  }
}
