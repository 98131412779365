/*
  Lozily load video.js stuff from main.js when it's detected to be needed.
*/

import { findAll, whenSelectorPresentAll } from '../../../common/helpers/dom'
import { sizeWrapper } from './wrapper'
import './placeholder.scss'
import { onMobileAppChromecastLoadedIfAvailable } from './custom_plugins/mobile_app_chromecast'

const initializeVideojs = async () => {
  const { initializeVideoPlayer } = await import('./main.js')

  onMobileAppChromecastLoadedIfAvailable(() => {
    findAll('video:not(.no-video-js):not(.video-js--initialized)').forEach(
      async (video) => {
        initializeVideoPlayer(video)
      }
    )
  })
}

const initializeAudiojs = async () => {
  const { initializeAudioPlayer } = await import('./main.js')
  findAll(
    'audio:not(.jp-audio):not(.no-video-js):not(.video-js--initialized)'
  ).forEach(async (audio) => {
    initializeAudioPlayer(audio)
  })
}

let ranInitializationAlready = false
window.initializeAllVideojs = () => {
  if (ranInitializationAlready) return
  ranInitializationAlready = true
  initializeVideojs()
  initializeAudiojs()
}

// If we're somewhere with turbolinks, navigation should reset this flag so that more videos can be initialized.
document.addEventListener('page:change', () => {
  ranInitializationAlready = false
})

// Initialize if it's added after load.
if (window.onDomChanged) {
  window.onDomChanged('video', initializeVideojs)
  window.onDomChanged('audio', initializeAudiojs)
}

// As soon as one video.js is on screen, we'll initialize them all. We also need to watch out for dynamic adds by
// the site builder. Calling initializeAllVideojs multiple times is fine as it's a no-op if the video has already been
// initialized.
whenSelectorPresentAll(
  'video:not(.no-video-js):not(.video-js--initialized), audio:not(.jp-audio):not(.no-video-js):not(.video-js--initialized)',
  (el) => {
    if ('IntersectionObserver' in window) {
      let observer = new IntersectionObserver(
        (entries) => {
          if (entries.some((entry) => entry.isIntersecting)) {
            window.initializeAllVideojs()
          }
        },
        { rootMargin: '400px' }
      )

      observer.observe(el)
      // Prevent a different sizing prior to video.js being loaded.
      sizeWrapper(el)
    } else {
      // Fall back, initialize now
      window.initializeAllVideojs()
    }
  }
)
